import CookieConsent from "react-cookie-consent";

type CookieConsentCardProps = {
  locale: string | undefined
}

export function CookieConsentCard({ locale }: CookieConsentCardProps) {
  const buttonText = locale === "en" ? "Accept all." : "Aceitar todos.";
  const message = locale === "en" ? "We use cookies to enhance your experience." : "Nós usamos cookies para melhorar sua experiência.";

  return <CookieConsent
    location="bottom"
    buttonText={buttonText}
    cookieName="cookie-consent"
  >{message}</CookieConsent>
}

import React, { useContext, useEffect, useState } from "react";
const ServerDefaults = {
  isMounted: false,
};

const ServerContext = React.createContext(ServerDefaults);

export const ServerContextProvider: React.FC<React.PropsWithChildren> = (
  props,
) => {
  const [isMounted, setIsMounted] = useState(ServerDefaults.isMounted);
  useEffect(() => {
    setIsMounted(true);
  }, []);
  return <ServerContext.Provider value={{ isMounted }} {...props} />;
};

export const useIsMounted = () => useContext(ServerContext).isMounted;

import { ptBR, enUS } from "date-fns/locale";
import { z } from "zod";

export const localeValidator = z.enum(["pt", "en"]).default("pt");

export const zodFile = z.custom<File>((v) => v instanceof File, {
  message: "Image is required",
});

export const dateLocaleMap = {
  pt: ptBR,
  en: enUS,
};
export const getTypeText = (type: string, locale = "pt") => {
  if (locale === "en") {
    switch (type) {
      case "MARKETING":
        return "Courtesy";
      case "REGULAR":
        return "Regular";
      case "STUDENT":
        return "Student Discount";
      case "PROMOTIONAL":
        return "Promotion Discount";
      default:
        return "";
    }
  }
  switch (type) {
    case "MARKETING":
      return "Cortesia";
    case "REGULAR":
      return "Inteira";
    case "STUDENT":
      return "Meia Entrada";
    case "PROMOTIONAL":
      return "Promocional";
    default:
      return "";
  }
};

export const formatBrl = (value: number) => {
  if (isNaN(value)) {
    return new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }).format(0);
  }
  return new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  }).format(value);
};

/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { CookieConsentCard } from "@components/CookieConsentCard";
import { ErrorBoundary } from "@components/ErrorBoundary";
import { Toaster } from "@components/ui/Sonner";
import { env } from "@env/client.mjs";
import { ServerContextProvider } from "@hooks/isServer";
import "@inovua/reactdatagrid-community/index.css";
import "@inovua/reactdatagrid-community/theme/default-dark.css";
import { PageProvider } from "@providers/page";
import "@public/odometer-theme-default.css";
import "@styles/globals.css";
import { calSans, dmSans } from "@utils/fonts";
import { network } from "@utils/index";
import { trpc } from "@utils/trpc";
import { dateLocaleMap } from "common";
import { setDefaultOptions } from "date-fns";
import "glider-js/glider.min.css";
import type { NextPage } from "next";
import type { Session } from "next-auth";
import { SessionProvider } from "next-auth/react";
import { NextIntlClientProvider } from "next-intl";
import type { AppProps } from "next/app";
import dynamic from "next/dynamic";
import Head from "next/head";
import { useRouter } from "next/router";
import posthog from "posthog-js";
import { PostHogProvider } from "posthog-js/react";
import { useEffect, type ReactElement, type ReactNode } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import "react-loading-skeleton/dist/skeleton.css";
import "react-tooltip/dist/react-tooltip.css";
// if this errors, one of the translations is missing a key
true satisfies Valid;

export type NextPageWithLayout<P = object, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

if (typeof window !== "undefined") {
  posthog.init(env.NEXT_PUBLIC_POSTHOG_KEY, {
    api_host: env.NEXT_PUBLIC_POSTHOG_HOST,

    //  loaded: (posthog) => {
    //    if (process.env.NODE_ENV === "development") posthog.debug();
    //  },
  });
}

const DevMenu = dynamic(
  () => {
    if (network === "matic") return Promise.resolve(() => <></>);
    return import("@components/DevMenu").then(({ DevMenu }) => DevMenu);
  },
  { ssr: false, loading: () => <></> }
);

const MyApp = ({
  Component,
  pageProps: { session, ...pageProps },
}: AppPropsWithLayout) => {
  const getLayout = Component.getLayout || ((page) => page);
  const { locale } = useRouter();
  setDefaultOptions({
    locale: dateLocaleMap[locale as "pt" | "en"],
  });
  const router = useRouter();
  useEffect(() => {
    const handleRouteChange = () => posthog?.capture("$pageview");
    router.events.on("routeChangeComplete", handleRouteChange);
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events]);

  return (
    <>
      <Head>
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
        <title>Openticket</title>
      </Head>
      <ErrorBoundary>
        <ServerContextProvider>
          <SessionProvider session={session as Session | null}>
            <NextIntlClientProvider messages={pageProps.messages}>
              <PostHogProvider client={posthog}>
                <PageProvider>
                  <DndProvider backend={HTML5Backend}>
                    {getLayout(
                      <>
                        <DevMenu />
                        <div
                          className={`${dmSans.variable} ${calSans.variable} h-full antialiased`}
                          style={{
                            textRendering: "optimizeLegibility",
                          }}
                        >
                          <Component {...pageProps} />
                        </div>
                        <CookieConsentCard locale={locale} />
                        <Toaster />
                      </>
                    )}
                  </DndProvider>
                </PageProvider>
              </PostHogProvider>
            </NextIntlClientProvider>
          </SessionProvider>
        </ServerContextProvider>
      </ErrorBoundary>
    </>
  );
};

export default trpc.withTRPC(MyApp);

import { useRouter } from "next/router";
import { createContext, useCallback, useEffect, useState } from "react";

export type Theme = "light" | "dark";
export type SkeletonTheme = {
  baseColor?: string;
  highlightColor?: string;
  style: Record<string, string>;
};
export interface ThemeContextProps {
  theme: Theme;
  skeletonTheme: SkeletonTheme;
  handleTheme(): void;
}

export const ThemeContext = createContext({} as ThemeContextProps);

export const ThemeProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const [theme, setTheme] = useState<Theme>("light");
  // Theme for loading skeleton components
  const skeletonTheme = {
    baseColor: "transparent",
    highlightColor: "#9f9f9f22",
    style: {
      backgroundColor: "#8e8e8e12",
    },
  };
  const router = useRouter();
  // Initial theme load from cookies and local storage
  const loadTheme = useCallback(() => {
    const prefersDarkTheme = window.matchMedia(
      "(prefers-color-scheme: dark)",
    ).matches;

    const preferedTheme = localStorage.theme as Theme;
    if (!preferedTheme) {
      if (prefersDarkTheme) {
        localStorage.theme = "dark";
        setTheme("dark");
      }
    } else {
      setTheme(preferedTheme);
    }
  }, []);

  const handleTheme = () => {
    const nextTheme: Theme = theme === "dark" ? "light" : "dark";
    localStorage.theme = nextTheme;
    setTheme(nextTheme);
  };

  useEffect(() => {
    loadTheme();
  }, [loadTheme]);

  useEffect(() => {
    const updateTheme = () => {
      if (
        theme === "light" &&
        document.documentElement.classList.contains("dark")
      )
        document.documentElement.classList.remove("dark");
      if (
        theme === "dark" &&
        document.documentElement.classList.contains("light")
      )
        document.documentElement.classList.remove("light");
      setTimeout(() => document.documentElement.classList.add(theme));
    };
    updateTheme();
    router.events.on("routeChangeComplete", updateTheme);
    return () => {
      router.events.off("routeChangeComplete", updateTheme);
    };
  }, [theme, router]);

  return (
    <ThemeContext.Provider
      value={{
        theme,
        skeletonTheme,
        handleTheme,
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};

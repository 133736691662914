import {
  type ExtraOptions,
  type LoadingModalHandle,
} from "@components/LoadingModal.jsx";
import { type Web3AuthConnector } from "@web3auth/web3auth-wagmi-connector";
import { type BigNumberish } from "alchemy-sdk";
import { addressTransformer } from "common/validators/values";
import { type User } from "database";
import { formatUnits, parseUnits } from "ethers/lib/utils";
import md5 from "md5";
import getConfig from "next/config";
import type { FormEvent } from "react";
import type {
  FieldValues,
  SubmitHandler,
  UseFormReturn,
} from "react-hook-form";
import { type Connector } from "wagmi";
import { z } from "zod";
import { env } from "../env/client.mjs";
import { Fail, SignatureRejected } from "./loadingStates";
import { type SUPPORTED_NETWORKS } from "./contracts";

const LOCALES = ["EN", "PT"];
const AddressZero = "0x0000000000000000000000000000000000000000";
export const simplifyAddress = (address: string) =>
  `0x${address.slice(2, 5)}...${address.slice(-3)}`;

export const usernameFallback = (user: Pick<User, "address" | "name">) => {
  let fallback = "";
  if (user.name) {
    const [first, second] = user.name.split(" ");
    if (first?.at(0)) fallback += first.at(0)?.toUpperCase();
    if (second?.at(0)) fallback += second.at(0)?.toUpperCase();
    else fallback += first?.at(1)?.toUpperCase();
  }
  if (fallback.length !== 2) {
    fallback = user.address.slice(-2);
  }
  return fallback;
};

export const formatValue = (value: number) => {
  if (value > 1000000) {
    return `${(value / 1000000).toFixed(1)}M`;
  }
  if (value > 1000) {
    return `${(value / 1000).toFixed(1)}K`;
  }
  return value;
};

export const parseAddress = (str: string | null = AddressZero) => {
  const address = addressTransformer.safeParse(str);
  if (address.success) {
    return address.data;
  }
  return AddressZero;
};

export const convertWeiStringToEther = (
  wei: string,
  unit: string | number = "ether"
) => formatUnits(wei, unit);

export const convertEtherWeiStringToString = (
  ether: string,
  unit: string | number = "ether"
) => parseUnits(ether, unit).toString();

export const network: SUPPORTED_NETWORKS = (() => {
  switch (env.NEXT_PUBLIC_BLOCKCHAIN_ENV) {
    case "development":
    case "staging":
      return "sepolia";
    case "production":
      return "matic";
    case "test":
      return "hardhat";
  }
})();

export const generateArrayFromNumber = (number: number) => {
  return Array.from(Array(number).keys());
};

type GratavarDefaults =
  | "mp"
  | "identicon"
  | "monsterid"
  | "wavatar"
  | "retro"
  | "robohash"
  | "blank";

export const defaultUserImage = (
  user: Pick<User, "address">,
  defaults: GratavarDefaults = "retro"
) => {
  return `https://www.gravatar.com/avatar/${md5(
    user.address
  )}.jpg?d=${defaults}`;
};

export const filterTransfersByTokenId = <T extends { tokenId: BigNumberish }>(
  transfers: T[],
  tokenId: string
) => {
  return transfers.filter((txn) => {
    if (!txn.tokenId) {
      return false;
    }
    return parseInt(txn.tokenId.toString()) === Number(tokenId);
  });
};
export const retrySignature = (
  error: Error,
  loader: LoadingModalHandle | null,
  onClick: ExtraOptions["onClick"]
) => {
  if (error.message.toLowerCase().includes("signature")) {
    loader?.push(
      SignatureRejected({
        extra: [
          {
            onClick,
            title: "Sign",
          },
        ],
      })
    );
  } else {
    loader?.push(
      Fail({
        message: error.message,
        extra: [
          {
            onClick,
            title: "Retry",
          },
        ],
      })
    );
  }
};

export const isImageUrlInConfig = (str: string) => {
  const { publicRuntimeConfig } = getConfig() as {
    publicRuntimeConfig: Record<string, string>;
  };
  const domains = z.array(z.string()).parse(publicRuntimeConfig.domains);
  return domains.find((domain) => str.includes(domain));
};

export const parseLocaleFromReq = (url: string) => {
  const parsedUrl = new URL(url);
  if (parsedUrl.pathname.startsWith("/en")) {
    return "en" as const;
  }
  return "pt" as const;
};

export const parseDomainFromReq = (referer: string) => {
  const [, , domainOrLocale, domain] = referer.split("/").filter(Boolean) || [];
  const isNotLocale =
    domainOrLocale && !LOCALES.includes(domainOrLocale.toUpperCase());
  const finalDomain = isNotLocale ? domainOrLocale : domain;
  return z.string().min(1).safeParse(finalDomain);
};

export const formatEventDate = (date: Date) => {
  return new Date(date).toLocaleDateString("en-US", {
    month: "long",
    day: "numeric",
    // year: "numeric",
    hour: "numeric",
    minute: "numeric",
  });
};

export const filterEmptyStrings = (obj: Record<string, string>) => {
  return Object.keys(obj).reduce((acc, key) => {
    const str = obj[key];
    if (str) {
      acc[key] = str;
    }
    return acc;
  }, {} as Record<string, string>);
};

export const isWeb3AuthConnector = (
  connector: Connector
): connector is Web3AuthConnector => {
  return connector.id.startsWith("web3auth");
};

export const sanitizeString = async (str: string) => {
  const { load } = await import("cheerio");
  const t = load(await sanitizeHtml(str));
  t("p").append("<br/>");
  const text = t("body")
    .find("br")
    .replaceWith("\n")
    .end()
    .find("p")
    .text()
    .replace(/\n+/g, "\n");
  return text;
};

export const sanitizeHtml = async (str: string) => {
  const { default: DOMPurify } = await import("isomorphic-dompurify");
  return DOMPurify.sanitize(str, { USE_PROFILES: { html: true } }).trim();
};

export const compactStringWhiteSpaces = (str: string) => {
  return str.replace(/\s\s+/g, " ");
};

export const validateCPF = (cpf: string) => {
  const sanitizedCPF = removeNonNumbers(cpf);

  if (sanitizedCPF.length !== 11) {
    return false;
  }

  if (/^(\d)\1+$/.test(sanitizedCPF)) {
    return false;
  }

  let sum = 0;
  for (let i = 0; i < 9; i++) {
    sum += parseInt(sanitizedCPF.charAt(i)) * (10 - i);
  }
  let verificationDigit = (sum * 10) % 11;
  verificationDigit = verificationDigit === 10 ? 0 : verificationDigit;
  if (verificationDigit !== parseInt(sanitizedCPF.charAt(9))) {
    return false;
  }

  sum = 0;
  for (let i = 0; i < 10; i++) {
    sum += parseInt(sanitizedCPF.charAt(i)) * (11 - i);
  }
  verificationDigit = (sum * 10) % 11;
  verificationDigit = verificationDigit === 10 ? 0 : verificationDigit;
  if (verificationDigit !== parseInt(sanitizedCPF.charAt(10))) {
    return false;
  }

  return true;
};

export const removeNonNumbers = (input: string) => input.replace(/[^0-9]/g, "");

export const censorCPF = (cpf: string) => {
  const clearCpf = removeNonNumbers(cpf);
  return `${clearCpf.slice(0, 2)}*.***.***-${clearCpf.slice(-2)}`;
};

export const formatCpf = (input: string) => {
  const cleaned = removeNonNumbers(input).padStart(11, "_");
  return `${cleaned.slice(0, 3)}.${cleaned.slice(3, 6)}.${cleaned.slice(
    // Nice
    6,
    9
  )}-${cleaned.slice(9, 11)}`;
};

export const formSubmitRewrap =
  <TFields extends FieldValues>(
    form: UseFormReturn<TFields>,
    onSubmit: SubmitHandler<TFields>
  ) =>
  (e: FormEvent) => {
    return new Promise((res, rej) => {
      void form.handleSubmit(
        async (data) => {
          await onSubmit(data);
          res(null);
        },
        (err) => rej(err)
      )(e);
    });
  };
